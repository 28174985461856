/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenContainer,
	DrivenTypography,
} from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import * as React from 'react';

interface PageHeaderProps {
	title: string;
}

export default function PageHeader({ title }: PageHeaderProps) {
	const theme = useTheme();

	return (
		<DrivenBox
			sx={{
				backgroundColor: theme.palette.common.black,
			}}
		>
			<DrivenContainer maxWidth='lg'>
				<DrivenBox py={{ xs: 3, sm: 4, md: 6 }}>
					<DrivenTypography variant='h2Bold' component='h1' color='white'>
						{title}
					</DrivenTypography>
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
}
