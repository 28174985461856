/* eslint-disable react/prop-types */
import * as React from 'react';
import {
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenTypography,
} from '@iterateai/driven-components';
import PageHeader from '../components/page-header';
import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';
import useIsClient from '../hooks/use-is-client';

const Page = () => {
	const { isClient, key } = useIsClient();

	if (!isClient) return null;

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenBox py={{ xs: 3, sm: 4, md: 6 }}>
				<DrivenTypography variant='h2Bold' component='h1' mb={3}>
					404: Page Not Found
				</DrivenTypography>

				<DrivenTypography variant='subtitle2' component='p' mb={8}>
					The page you’re looking for cannot be found. Let’s get you back on
					track!
				</DrivenTypography>

				<DrivenButton
					color='primary'
					variant='contained'
					href={'/'}
					LinkComponent={GatsbyLink}
				>
					Take Me Home
				</DrivenButton>
			</DrivenBox>
		</DrivenContainer>
	);
};

export default Page;
